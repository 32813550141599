import React from 'react';

import './UnderConstruction.css'; // Optional for styling
import ImageComponent from './ImageComponent'; // Adjust the path if needed
import { Helmet } from 'react-helmet';

const UnderConstruction = () => (
  <div className="UnderConstruction">
     <Helmet>
      <title>Thin Lines - Beyond the Lines</title>
      <meta name="description" content="Thin Lines is a premier marketing agency specializing in innovative strategies and solutions to elevate your brand." />
      <meta name="keywords" content="Thin Lines, marketing agency, digital marketing, brand strategy, SEO, social media" />
      <meta property="og:title" content="Thin Lines - Leading Marketing Agency" />
      <meta property="og:description" content="Thin Lines is a premier marketing agency specializing in innovative strategies and solutions to elevate your brand." />
      <meta property="og:image" content="https://objectstorage.me-abudhabi-1.oraclecloud.com/n/axipuvjwqndg/b/bucket-images/o/thinLines.png" />
      <meta property="og:url" content="https://thinlines.marketing" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Thin Lines - Leading Marketing Agency" />
      <meta name="twitter:description" content="Thin Lines is a premier marketing agency specializing in innovative strategies and solutions to elevate your brand." />
      <meta name="twitter:image" content="https://objectstorage.me-abudhabi-1.oraclecloud.com/n/axipuvjwqndg/b/bucket-images/o/thinLines.png" />
    </Helmet>

  <a href="/">  {/* Redirect to home page */}
  <ImageComponent src="thinLines.png" alt="Thin Lines" className="construction-img img1" />
  </a>


    <ImageComponent src="ComingSoonAnimation.gif" alt="Thin Lines" className="construction-img img2" />
   
    
    <p className="my-paragraph">Ready To Make <br />
      Some Noise?
    </p>

  </div>
);

export default UnderConstruction;