import React from 'react';
import './UnderConstruction.css'; // Ensure path is correct

const ImageComponent = ({ src, alt, className }) => {
    // Define the image sources for different screen sizes
    const imageSrcSet = `
      https://objectstorage.me-abudhabi-1.oraclecloud.com/n/axipuvjwqndg/b/bucket-images/o/300x200${src} 300w,
      https://objectstorage.me-abudhabi-1.oraclecloud.com/n/axipuvjwqndg/b/bucket-images/o/600x400${src} 600w,
      https://objectstorage.me-abudhabi-1.oraclecloud.com/n/axipuvjwqndg/b/bucket-images/o/600x400${src} 800w,
      https://objectstorage.me-abudhabi-1.oraclecloud.com/n/axipuvjwqndg/b/bucket-images/o/${src} 1200w
    `;
  
    // Define sizes based on media queries
    const imageSizes = `
      (max-width: 600px) 100vw,
      (max-width: 800px) 80vw,
       (max-width: 1200px) 50vw,
      1200px
    `;
  
    return (
      <img
        src={`https://objectstorage.me-abudhabi-1.oraclecloud.com/n/axipuvjwqndg/b/bucket-images/o/${src}`} // Fallback image
        srcSet={imageSrcSet}
        sizes={imageSizes}
        alt={alt}
        className={className} // Apply className for styling
        loading="lazy" // Lazy load the image

      />
    );
  };
  
  export default ImageComponent;