import React from 'react';
import './LoadingSpinner.css'; // Optional CSS for spinner styling

const LoadingSpinner = () => {
  return (
    <div className="loading-page">
        <img src="Loading.gif" alt="Loading..." />
    </div>
  );
};

export default LoadingSpinner;