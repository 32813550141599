// src/MainApp.js
import React from 'react';
import './MainApp.css'; // Optional for styling

const MainApp = () => {
  return (
    <div className="main-app">
      <header className="app-header">
        <h1>Welcome to My React App</h1>
        <nav>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
      </header>
      <main>
        <section id="home">
          <h2>Home</h2>
          <p>This is the home section of your React app.</p>
        </section>
        <section id="about">
          <h2>About</h2>
          <p>Learn more about us in this section.</p>
        </section>
        <section id="contact">
          <h2>Contact</h2>
          <p>Get in touch with us through this section.</p>
        </section>
      </main>
      <footer>
        <p>&copy; 2024 My React App. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default MainApp;
