import React, { useState, useEffect } from 'react';
import MainApp from './MainApp/MainApp';
import UnderConstruction from './underConstruction/UnderConstruction';
import './global.css'; // Import global styles
import LoadingSpinner from './LoadingSpinner/LoadingSpinner'; // Import the loading spinner component


function App() {
  const [loading, setLoading] = useState(true);
  const isUnderConstruction = true;

  useEffect(() => {

    const checkDocumentReady = () => {
      if (document.readyState === 'complete') {
        setLoading(false);
      }
    };

    checkDocumentReady();

    // Listen for the window load event
    window.addEventListener('load', checkDocumentReady);
    


    //Disable right-click
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    // Disable drag-and-drop
    const handleDragStart = (event) => {
      event.preventDefault();
    };

    // Disable text selection
    const handleSelectStart = (event) => {
      event.preventDefault();
    };

    // Add event listeners
    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('dragstart', handleDragStart);
    document.addEventListener('selectstart', handleSelectStart);

    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('dragstart', handleDragStart);
      document.removeEventListener('selectstart', handleSelectStart);
    };
  }, []);

  if (loading) {
    return <LoadingSpinner />; // Show loading spinner when loading is true
  }

  return (
    <div className="App">
      {isUnderConstruction ? <UnderConstruction /> : <MainApp />}
    </div>
  );
}

export default App;
